

























































































import { Component, Vue } from 'vue-property-decorator'
import { getAccounts, wireTransferEUR } from '@/clients/cpinblocks'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError, convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { ValidationRules } from '@/types'

@Component({
	components: {
		CurrencyInput,
		Code2FADialog,
	},
})
export default class AddWireTransferEUR extends Vue {
	private isValid = false
	private show2FADialog = false
	private amountPrecision = 2
	private amountMax: BigNumber = new BigNumber('0')
	private iban = ''
	private creating = false
	private loading = true
	private amount = new BigNumber('0')

	get ibans (): any[] {
		const result = []
		for (const iban of this.$store.state.owner.ibans) {
			if (iban.accountHolderFullName !== undefined && iban.accountHolderFullName !== '') {
				result.push({
					value: iban.value,
					name: (iban.blocked ? 'Blocked - ' : '') + (iban.value + (iban.label ? ' (' + iban.label + ')' : '')),
				})
			}
		}
		return result
	}

	private get rules (): ValidationRules {
		return {
			required: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
			],
			amount: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => convertValueToBigNumber(v).gte(10) || this.$t('ftmpOffer.rule.min', { min: 10 }),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => {
					return convertValueToBigNumber(v).isLessThanOrEqualTo(this.amountMax) || this.$t('ftmpOffer.rule.max', {
						max: formatFixedDecimals(this.$i18n, this.amountMax, this.amountPrecision),
					})
				},
			],
		}
	}

	async created (): Promise<void> {
		await this.reset()
		this.loading = false
	}

	async cancel (): Promise<void> {
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async withdrawEUR (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		try {
			// console.log(this.amount + ' ' + this.iban)
			await wireTransferEUR(this.$store.state.jwt, this.amount, this.iban, code2FA)
			this.creating = false
			this.$emit('success')
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
		}
	}

	async reset (): Promise<void> {
		this.loading = true
		this.loading = false
		const accounts = await getAccounts(this.$store.state.jwt, false)
		for (const a of accounts) {
			if (a.currency === 'EUR' && a.type === 'MAIN') {
				this.amountMax = a.balance
			}
		}
		this.validateForm()
	}

	validateForm (): void {
		if (!this.loading) {
			(this.$refs.form as Vue & { validate: () => boolean }).validate()
		}
	}
}
