import { i18n } from '@/i18n'
import { Account } from '@/models/Account'
import { App3rdParty } from '@/models/App3rdParty'
import { BuybackBurn } from '@/models/BuybackBurn'
import { BBBListItem } from '@/models/BuybackBurnListItem'
import { Conf } from '@/models/Conf'
import { DataExplorer } from '@/models/DataExplorer'
import { FTMPOffer } from '@/models/FTMPOffer'
import { NFTCryptopolitics } from '@/models/NFTCryptopolitics'
import { Rate } from '@/models/Rate'
import { Staking } from '@/models/Staking'
import { Iban, User } from '@/models/User'
import { Withdraw } from '@/models/Withdraw'
import { default as store, StoreState } from '@/store'
import { stringMapper } from '@/typedjson'
import type { Currency, Network, TokenType } from '@/types'
import { alertError, alertErrorWithButton, alertRawError, env, envInt, formatDate } from '@/utils'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { BigNumber } from 'bignumber.js'
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson'
import { Store } from 'vuex'
import moment from 'moment-timezone'
import { Fee } from '@/models/Fee'
import {
	Beneficial,
	CompanyIdentity,
	CompanyInformation,
	KYBInformation,
	KYCInformation,
	KYInformation,
} from '@/models/KYInformation'
import { KYState, KYType, VerificationFactor } from '@/models/KYState'
import qs from 'qs'
import download from 'downloadjs'

const regExpFilename = /filename="(?<filename>.*)"/

const api = axios.create({
	baseURL: env('VUE_APP_API_ENDPOINT'),
	timeout: envInt('VUE_APP_API_TIMEOUT'),
})

const connectApiBaseUrl = env('VUE_APP_CONNECT_API_ENDPOINT')
const connectApi = axios.create({
	baseURL: connectApiBaseUrl,
	timeout: envInt('VUE_APP_API_TIMEOUT'),
	maxRedirects: 0,
})

const onboardingApi = axios.create({
	baseURL: env('VUE_APP_ONBOARDING_API_ENDPOINT'),
	timeout: envInt('VUE_APP_API_TIMEOUT'),
	maxRedirects: 0,
})

const sireneApi = axios.create({
	baseURL: 'https://public.opendatasoft.com/api/explore/v2.0/catalog/datasets/economicref-france-sirene-v3',
	timeout: envInt('VUE_APP_API_TIMEOUT'),
	maxRedirects: 0,
})

// const searchApi = axios.create({
// 	baseURL: env('VUE_APP_SEARCH_API_ENDPOINT'),
// 	timeout: envInt('VUE_APP_API_TIMEOUT'),
// 	maxRedirects: 0,
// })

function displayError (error: AxiosError) {
	if (error.response?.status === 401) {
		alertError('cpinblocks.unauthorized')
	} else if (error.response?.status === 403) {
		if (error.response?.data.error === 'MAX_ATTEMPTS_REACHED') {
			alertError('cpinblocks.limitreached2fa')
		} else {
			alertError('cpinblocks.forbidden')
		}
	} else if (error.response?.status === 403) {
		if (error.response?.data.error === 'INVALID_CODE') {
			alertError('cpinblocks.wrong2facode')
		}
	} else if (error.response?.status === 429) {
		if (error.response?.data.message.startsWith('Too many requests')) {
			alertErrorWithButton('cpinblocks.toomanyrequests', 'button.gotit')
		}
	} else if (error.response?.status === 500) {
		alertError('cpinblocks.serverError')
	} else {
		alertError('cpinblocks.unknownError')
	}
}

let confCache: Conf | null = null

export type WithdrawalType = 'CURRENCY' | 'NFT'

export async function getAPIConfiguration (force = false): Promise<Conf | null> {
	if (confCache !== null && !force) {
		return confCache
	}
	const { data } = await api.get('/conf')
	confCache = TypedJSON.parse(data, Conf) as Conf
	return confCache
}

export async function getAccounts (jwt: string, operations: boolean): Promise<Account[]> {
	try {
		const { data } = await api.get(`/ledger/accounts?operations=${operations}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data.map((raw: any) => TypedJSON.parse(raw, Account))
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

export async function getAccountOperations (jwt: string, currencies: Currency | null, types: TokenType | null, operations: boolean): Promise<Account[]> {
	try {
		let url = `/ledger/accounts?operations=${operations}`
		if (currencies !== null) {
			url += `&currencies=${currencies.toUpperCase()}`
		}
		if (types !== null) {
			url += `&types=${types}`
		}
		const { data } = await api.get(url, { headers: { Authorization: 'Bearer ' + jwt } })
		return data.map((raw: any) => TypedJSON.parse(raw, Account))
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

async function updateStored2FA (code: string) {
	await store.commit('code2FA', code)
	await store.commit('code2FATimestamp', new Date().getTime() / 1000)
}

async function reset2FA () {
	await store.commit('code2FA', null)
	await store.commit('code2FATimestamp', 0)
}

@jsonObject
class Bid {
	@jsonMember sellerId?: string
	@jsonMember sellerAddress?: string
	@jsonMember buyerId!: string
	@jsonMember rate!: Rate
	@jsonMember(stringMapper) type!: TokenType
	@jsonMember(stringMapper) network!: Network
}

export async function withdraw (jwt: string, code: string, feeId: string, withdraw: Withdraw): Promise<void> {
	try {
		const { data } = await api.post(`/ledger/transferOut?code=${code}&feeId=${feeId}`, withdraw, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
		return data
	} catch (error: any) {
		if (error.response.status === 403 && error.response?.data?.error !== 'INVALID_CODE' && error.response?.data?.error !== 'MAX_ATTEMPTS_REACHED') {
			alertError('cpinblocks.invalidAddress')
		} else {
			error2fa(error, true)
		}
	}
}

export async function deleteIban (iban: string, jwt: string): Promise<void> {
	try {
		const { data } = await api.delete(`/iban/${iban}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		error2fa(error, true)
	}
}

export async function addIban (iban: Iban, ownerIsBeneficiary: boolean, jwt: string): Promise<void> {
	try {
		const { data } = await api.post('/iban', iban,
			{
				headers: {
					Authorization: 'Bearer ' + jwt,
				},
				params: {
					ownerIsBeneficiary: ownerIsBeneficiary,
				},
			})
		return data
	} catch (error: any) {
		if (error.response?.status === 409 && error.response?.data.error === 'IBAN_ALREADY_LINKED_TO_OWNER') {
			alertError('cpinblocks.IBAN_ALREADY_LINKED_TO_OWNER')
		} else if (error.response?.status === 409 && error.response?.data.error === 'IBAN_LINKED_TO_ANOTHER_OWNER') {
			alertError('cpinblocks.IBAN_LINKED_TO_ANOTHER_OWNER')
		}
	}
}

export async function editIban (iban: Iban, jwt: string): Promise<void> {
	try {
		const { data } = await api.put(`/iban/${iban.value}`, iban,
			{
				headers: {
					Authorization: 'Bearer ' + jwt,
				},
			})
		return data
	} catch (error: any) {
		error2fa(error, true)
	}
}

export async function faucet (jwt: string, currency: string, type: string): Promise<void> {
	try {
		const { data } = await api.post(`/faucet/${currency.toUpperCase()}/${type}`, {}, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		alertError(' ' + error.response.data.message + ' ' + formatDate(error.response.data.at, i18n))
		throw error
	}
}

export async function burn (jwt: string, currency: string, type: string): Promise<void> {
	try {
		const { data } = await api.delete(`/faucet/${currency.toUpperCase()}/${type}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		alertError(' ' + error.response.data.message + ' ' + formatDate(error.response.data.at, i18n))
		throw error
	}
}

export async function getStaking (jwt: string, id: string): Promise<Staking> {
	try {
		const { data } = await api.get(`/staking/${id}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return TypedJSON.parse(data, Staking) as Staking
	} catch (error: any) {
		alertRawError(error.response.data.message)
		throw error
	}
}

export async function claimRewards (jwt: string, id: string): Promise<void> {
	try {
		await api.post(`/staking/${id}/claimRewards`, undefined, { headers: { Authorization: 'Bearer ' + jwt } })
	} catch (error: any) {
		alertRawError(error.response.data.message)
		throw error
	}
}

async function error2fa (error: any, raw: boolean) {
	await reset2FA()
	if (error.response?.status === 403 && error.response?.data?.error === 'INVALID_CODE') {
		alertError('cpinblocks.wrong2facode')
	} else if (error.response?.status === 403 && error.response?.data?.error === 'MAX_ATTEMPTS_REACHED') {
		alertError('cpinblocks.limitreached2fa')
	} else if (error.response?.status === 409 && error.response?.data?.error === 'ONE_SHOT') {
		alertError('cpinblocks.2faCodeUniqueness')
	} else if (error.response?.status === 429) {
		alertError('cpinblocks.ratereached2fa')
	} else if (raw) {
		alertRawError(error.response.data.message)
	} else {
		displayError(error)
	}
}

export async function stake (jwt: string, id: string, amount: BigNumber, code: string): Promise<void> {
	try {
		await api.post(`/staking/${id}/stake?code=${code}`, { amount }, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function unstake (jwt: string, id: string, code: string): Promise<void> {
	try {
		await api.post(`/staking/${id}/unstake?code=${code}`, undefined, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function getPriceInUSDT (currency: Currency): Promise<BigNumber | null> {
	try {
		const { data } = await api.get(`/tmp/rates/${currency.toUpperCase()}_BUSD`)
		return new BigNumber(data.amountTo)
	} catch (error: any) {
		// do nothing but return null
		return null
	}
}

export async function getExplorerData (jwt: string, currency: Currency | undefined, size: number, offset: number, accountId: string | undefined, dayFrom: string | undefined, dayTo: string | undefined): Promise<DataExplorer | null> {
	try {
		let url = `/ledger/explore/movements?sortingOrder=DESC&size=${size}&offset=${offset}`
		if (currency) {
			url += `&currency=${currency.toUpperCase()}`
		}
		if (accountId) {
			url += `&accountId=${accountId}`
		}
		if (dayFrom) {
			url += `&dayFrom=${dayFrom}`
		}
		if (dayTo) {
			url += `&dayTo=${dayTo}`
		}
		const { data } = await api.get(url, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as DataExplorer
	} catch (error: any) {
		if (error.response?.status === 400 && error.response?.data?.message === 'Account does not exist') {
			return null
		} else {
			alertRawError(error.response.data.message ?? 'Error ' + error.response.status)
			throw error
		}
	}
}

export async function getNFTLastVersion (collection: string, tokenId: string): Promise<NFTCryptopolitics | null> {
	try {
		const { data } = await api.get(`/${collection}/nft/${tokenId}`)
		return data as NFTCryptopolitics
	} catch (error: any) {
		displayError(error)
	}
	return null
}

export async function getNFTRecordData (collection: string, tokenId: string, version?: number): Promise<any | undefined> {
	try {
		const s = `nft_${collection}_${tokenId}_v${version}`
		const hashBuffer = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(s))
		const hashArray = Array.from(new Uint8Array(hashBuffer))
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
		const { data } = await api.get(`/cryptopolitics/precedence/record/${hashHex}/data`)
		return data
	} catch (error: any) {
		displayError(error)
	}
}

export async function getPrecedenceRecordData (recordId: string): Promise<any | undefined> {
	try {
		const { data } = await api.get(`/cryptopolitics/precedence/record/${recordId}/data`)
		return data
	} catch (error: any) {
		displayError(error)
	}
}

export async function getBBB (jwt: string, id: number): Promise<BuybackBurn | undefined> {
	try {
		const { data } = await api.get(`/buyback/${id}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as BuybackBurn
	} catch (error: any) {
		displayError(error)
	}
}

export async function pushBBB (jwt: string, id: number, icoAmount: number, mainAmount: number, amount: number, currency: Currency, code: string): Promise<BuybackBurn | undefined> {
	try {
		const { data } = await api.post(`/buyback/${id}?code=${code}`, {
			amount: amount,
			currency: currency.toUpperCase(),
			POWER_ICO: icoAmount,
			POWER_MAIN: mainAmount,
		}, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
		return data as BuybackBurn
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function pullBBB (jwt: string, id: number, code: string): Promise<BuybackBurn | undefined> {
	try {
		const { data } = await api.delete(`/buyback/${id}?code=${code}`, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
		return data as BuybackBurn
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function getBBBList (): Promise<BBBListItem[] | null> {
	try {
		const { data } = await api.get('/buyback/list')
		return data as BBBListItem[]
	} catch (error: any) {
		displayError(error)
	}
	return null
}

// TODO: add a cache in the brower and a "force" boolean parameter to avoid too much call to the owner endpoint
export async function refreshOwner (jwt: string, store: Store<StoreState>): Promise<void | string> {
	try {
		const { data } = await api.get(`/ledger/owner?includePersonalInfo=${true}&includeInternalInfo=${true}`, { headers: { Authorization: 'Bearer ' + jwt } })
		store.commit('owner', new User(data))
	} catch (error: any) {
		if (error.response?.status === 400) {
			if (error.response?.data.error === 'MALFORMATTED_CIVIC_ID') {
				return 'MALFORMATTED_CIVIC_ID'
			} else if (error.response?.data.error === 'MISSING_CIVIC_ID') {
				return 'MISSING_CIVIC_ID'
			}
		} else {
			displayError(error)
		}
	}
}

@jsonObject
export class FTMPOfferGetRequest {
	@jsonMember(stringMapper) productCurrency?: Currency
	@jsonMember(stringMapper) productType?: TokenType
	@jsonMember(stringMapper) unitPriceCurrency?: Currency
	@jsonMember(stringMapper) unitPriceType?: TokenType
	@jsonMember seller?: string
	@jsonMember buyer?: string
	@jsonMember limit?: number
	@jsonMember offset?: number
	@jsonMember sort?: string
}

@jsonObject
export class FTMPOfferGetResponse {
	@jsonMember(FTMPOffer) results?: FTMPOffer[]
	@jsonMember total!: number
}

export async function getFTMPOffer (jwt: string, request: any): Promise<FTMPOfferGetResponse | undefined> {
	try {
		let url = '/marketplace/ft/availableOffers'
		let first = true
		for (const k of Object.keys(request)) {
			if (request[k] !== undefined && request[k] !== null) {
				if (first) {
					url += '?'
					first = false
				} else {
					url += '&'
				}
				url += k + '=' + (request[k] as string)
			}
		}
		const { data } = await api.get(url, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as FTMPOfferGetResponse
	} catch (error: any) {
		displayError(error)
	}
}

export async function createFTMPOffer (jwt: string, offer: FTMPOffer, code: string): Promise<void> {
	try {
		await api.post(`/marketplace/ft/offers?code=${code}`, offer, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function payFTMPOffer (jwt: string, offerId: string, amount: BigNumber, code: string): Promise<void> {
	try {
		await api.post(`/marketplace/ft/offers/${offerId}?code=${code}&amount=${amount}`, undefined, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function deleteFTMPOffer (jwt: string, offerId: string, code: string): Promise<void> {
	try {
		await api.delete(`/marketplace/ft/offers/${offerId}?code=${code}`, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export enum AddressState {
	NEUTRAL = 'NEUTRAL',
	BLACKLISTED = 'BLACKLISTED',
	WHITELISTED = 'WHITELISTED'
}

export class Address {
	value!: string
	updatedAt?: string
	ownerId?: string
	enabled!: boolean
	state!: AddressState
	stateChangeReason?: string
	stateChangeBy?: string
	stateChangeAt?: string
	name?: string
	first?: {
		at: string
		message: string
		signature: string
	}

	last?: {
		at: string
		message: string
		signature: string
	}
}

export type PersonalInformation = {
	ethereum?: {
		addresses?: Address[]
	}
	personalInformation?: {
		identification?: {
			firstName?: string
			lastName?: string
		}
		location?: {
			address?: string
			country?: string
			zipCode?: string
			city?: string
		}
		bank?: {
			iban?: string
		}
	}
	kyc?: boolean
}

function removeEmpty (obj: any): any {
	return Object.fromEntries(
		Object.entries(obj)
			.map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
			.filter(([_, v]) => v !== null && v !== undefined && v !== '' && (v !== Object(v) || Object.keys(v).length > 0))
	)
}

export async function updateBeneficialOwners (jwt: string, beneficials: Beneficial[]): Promise<boolean> {
	try {
		await api.put('/verification/kyb/beneficial-owners', beneficials, { headers: { Authorization: 'Bearer ' + jwt } })
		return true
	} catch (error: any) {
		if (error.response.status === 409) {
			alertRawError('You already sent the KY form')
		} else {
			displayError(error)
		}
		return false
	}
}

export async function updateCompanyInformation (jwt: string, companyInfo: CompanyInformation): Promise<boolean> {
	try {
		await api.put('/verification/kyb/company-information', companyInfo, { headers: { Authorization: 'Bearer ' + jwt } })
		return true
	} catch (error: any) {
		if (error.response.status === 409) {
			alertRawError('You already sent the KY form')
		} else {
			displayError(error)
		}
		return false
	}
}

export async function updateKYForm (jwt: string, kyInfo: KYInformation): Promise<boolean> {
	const ky: KYBInformation | KYCInformation = (kyInfo.formUtilities.corporateAccount)
		? Object.assign({}, kyInfo.kybInfo, { legalRepresentative: kyInfo.identity })
		: Object.assign({}, kyInfo.kycInfo, { identity: kyInfo.identity })
	const kyType = (kyInfo.formUtilities.corporateAccount) ? KYType.KYB : KYType.KYC
	try {
		await api.put(`/verification/${kyType.toLowerCase()}`, ky, { headers: { Authorization: 'Bearer ' + jwt } })
		return true
	} catch (error: any) {
		if (error.response.status === 409) {
			alertRawError(`You already sent the ${kyType} form`)
		} else {
			displayError(error)
		}
		return false
	}
}

export async function addWallet (jwt: string, message: string, address: string, signature: string): Promise<void | undefined> {
	try {
		const { data } = await api.post('/addresses', {
			message: message,
			address: address,
			signature: signature,
		}, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		if (error.response?.status === 409 && error.response?.data.error === 'WALLET_LINKED_TO_ANOTHER_OWNER') {
			alertError('cpinblocks.walletLinkedToAnotherAccount')
		} else if (error.response?.status === 409 && error.response?.data.error === 'WALLET_ALREADY_LINKED_TO_OWNER') {
			alertError('cpinblocks.walletAlreadyLinkedToAccount')
		} else if (error.response?.status === 403) {
			alertError('cpinblocks.walletAddForbidden')
		}
		alertRawError(error)
		return error
	}
}

export async function toggleAddress (jwt: string, address: string, state: boolean): Promise<void | undefined> {
	try {
		const { data } = await api.put(`/addresses/${address}/enabled/${state}`, null, {
			headers: {
				Authorization: 'Bearer ' + jwt,
				'Content-Type': 'application/json',
			},
		})
		return data
	} catch (error: any) {
		alertRawError(error)
		return error.response
	}
}

export async function editName (jwt: string, state: boolean, address: string, addressname: string): Promise<void | undefined> {
	try {
		const { data } = await api.put(`/addresses/${address}/name/${addressname}`, null, {
			headers: {
				Authorization: 'Bearer ' + jwt,
				'Content-Type': 'application/json',
			},
		})
		return data
	} catch (error: any) {
		alertRawError(error)
		return error.response
	}
}

@jsonObject
export class JumioKYDocVerification {
	@jsonMember timestamp!: string
	@jsonMember transactionReference!: string
	@jsonMember redirectUrl!: string
	@jsonMember reason?: string
}

export async function getJumioKYDocVerification (jwt: string): Promise<JumioKYDocVerification | boolean | undefined> {
	try {
		const { data } = await api.get('/verification/id-document', { headers: { Authorization: 'Bearer ' + jwt } })
		return data as JumioKYDocVerification
	} catch (error: any) {
		if (error.response?.status === 403 || error.response?.data.error === 'Please contact support') {
			alertRawError('Your KY procedure has been locked due to an excessive number of failed attempt, please contact support')
			return true
		} else {
			alertRawError(error)
		}
	}
}

export async function getKYState (jwt: string): Promise<KYState> {
	try {
		const response = (await api.get('/verification/state', { headers: { Authorization: 'Bearer ' + jwt } })).data as KYState
		return new KYState(response)
	} catch (error: any) {
		alertRawError(error)
		return new KYState(null)
	}
}

export type Error = {
	data?: {
		error?: string
		'Key used'?: string
		'on user'?: string
		message?: string
	}
	status?: number
	statusText?: string
	headers?: {
		'content-length'?: string
		'content-type'?: string
	}
	config?: {
		url?: string
		method?: string
		data?: string
		headers?: {
			Accept?: string
			'Content-Type'?: string
			Authorization?: string
		}
		baseURL?: string
		transformRequest?: [
			null
		],
		transformResponse?: [
			null
		],
		timeout?: number
		xsrfCookieName?: string
		xsrfHeaderName?: string
		maxContentLength?: number
		maxBodyLength?: number
	}
	request?: any
}

export type QRandSecretKey = {
	imageB64: string,
	key: string
}

export async function initialize2faSetup (jwt: string): Promise<QRandSecretKey | undefined> {
	try {
		const { data } = await api.get('/2fa/initialize', { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		displayError(error)
	}
}

export async function finalize2faSetup (jwt: string, code: string): Promise<AxiosResponse> {
	return await api.post(`/2fa/finalize?code=${code}`, null, {
		headers: {
			Authorization: 'Bearer ' + jwt,
			'Content-Type': 'application/json',
		},
	})
}

export type CustodialAddressStatus = {
	network: Network,
	currency: Currency,
	address?: string,
	status: string,
}

export async function getDepositAddress (jwt: string, currency: Currency, network: Network): Promise<CustodialAddressStatus | null> {
	try {
		const { data } = await api.get(`/addresses/deposit?currency=${currency.toUpperCase()}&network=${network}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as CustodialAddressStatus
	} catch (error: any) {
		displayError(error)
	}
	return null
}

export type AmountGivenByUser = {
	currency: Currency,
	email: string,
	ibexUserId: string,
	id: number,
	pledgeAmount: number,
	pledgeTime: string,
	tokenEmissionEventId: number
}

export type PledgeTotal = {
	amount: BigNumber,
	currency: string,
	numUsers: number
}

export type AmountGivenTotal = {
	startDate: string,
	total: PledgeTotal
}

export async function getAmountGivenByUser (jwt: string, icoName: string): Promise<AmountGivenByUser[] | undefined> {
	try {
		const { data } = await api.get(`/onboarding/token-emission-event/${icoName}/pledges/users/`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error) {
		displayError(error)
	}
}

export async function getAmountGivenByUsers (jwt: string, icoName: string): Promise<AmountGivenTotal[] | undefined> {
	try {
		const { data } = await api.get(`/onboarding/token-emission-event/${icoName}/pledges/statistics?priority=last&cumulative=true&slotSize=millenium`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error) {
		displayError(error)
	}
}

export type CurrencyTypeValueTuple = {
	currency: Currency,
	type: TokenType,
	value: string,
}

export type TransferOutFee = {
	feeId: string,
	network: Network,
	createdAt: string,
	expiresAt: string,
	fees: CurrencyTypeValueTuple[]
}

export async function getTransferOutFee (jwt: string, currency: Currency, network: Network, amount: BigNumber): Promise<TransferOutFee | null> {
	try {
		const { data } = await api.get(`/ledger/transferOutFee?network=${network}&currency=${currency.toUpperCase()}&amount=${amount}`, { headers: { Authorization: 'Bearer ' + jwt } })
		const result = data as TransferOutFee
		const localTime = new Date().getTime()
		const serverTime = new Date(result.createdAt).getTime()
		result.expiresAt = new Date(localTime - serverTime + new Date(result.expiresAt).getTime()).toISOString()
		return result
	} catch (error) {
		displayError(error)
	}
	return null
}

function makeid (length: number) {
	let result = ''
	const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export type MBRewards = {
	currency: Currency
	claimable: string
	claimed: string
	type: TokenType
}

export type AmountCurrencyTuple = {
	amount: string,
	currency: Currency,
}

export type MBInfo = {
	staked?: AmountCurrencyTuple[],
	rewards?: MBRewards[]
}

export async function getMagicBarn (jwt: string): Promise<MBInfo | null> {
	try {
		const { data } = await api.get('/magicBarn', { headers: { Authorization: 'Bearer ' + jwt } })
		return data as MBInfo
	} catch (error) {
		displayError(error)
	}
	return null
}

export async function stakeMagicBarn (jwt: string, code: string, amount: BigNumber, currency: Currency): Promise<void> {
	try {
		await api.post(`/magicBarn/stake?code=${code}`, {
			id: makeid(16),
			amount,
			currency: currency.toUpperCase(),
		}, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function unstakeMagicBarn (jwt: string, code: string, amount: BigNumber, currency: Currency): Promise<void> {
	try {
		await api.post(`/magicBarn/unstake?code=${code}`, {
			id: makeid(16),
			amount,
			currency: currency.toUpperCase(),
		}, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function claimMagicBarn (jwt: string, currency: Currency, type: TokenType): Promise<void> {
	try {
		await api.post('/magicBarn/claim', {
			id: makeid(16),
			currency: currency,
			type: type,
		}, { headers: { Authorization: 'Bearer ' + jwt } })
	} catch (error) {
		displayError(error)
		throw error
	}
}

export type BreakingMode = 'FORBIDDEN' | 'INTERESTS_LOST' | 'INTERESTS_KEPT'

TypedJSON.mapType(BigNumber, {
	deserializer: (value) => value == null ? value : new BigNumber(value),
	serializer: (value) => value == null ? value : value.toString(),
})

@jsonObject
export class TermDepositConf {
	@jsonMember id!: string
	@jsonMember enabled!: boolean
	@jsonMember ownerId!: string
	@jsonMember label!: string
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
	@jsonMember annualPercentageRate!: string
	@jsonMember(stringMapper) breakingMode!: BreakingMode
	@jsonMember stakeFeePercentage!: string
	@jsonMember hardCap!: string
	@jsonMember balance!: string
	@jsonMember(Date) subscriptionStartAtInclusive!: string
	@jsonMember(Date) subscriptionEndAtExclusive!: string
	@jsonMember(Date) startAtInclusive!: string
	@jsonMember(Date) endAtExclusive!: string
}

export type TermDepositAction = 'STAKE' | 'UNSTAKE'

@jsonObject
export class TermDepositItem {
	conf!: TermDepositConf
	@jsonMember(Date) at?: string
	@jsonMember(stringMapper) action?: TermDepositAction
	@jsonMember amount?: string
	@jsonMember amountNet?: string
	@jsonMember claimedInterests?: string
	@jsonMember interests?: string
	@jsonMember interestsPerSec?: string
	@jsonMember(Date) updatedAt?: string
}

export async function getTermDeposits (jwt: string): Promise<TermDepositItem[]> {
	try {
		const { data } = await api.get('/termDeposits', { headers: { Authorization: 'Bearer ' + jwt } })
		return data as TermDepositItem[]
		// Mockup for tests
		/* return [
			{
				"conf": {
					"id": "abcdef012345",
					"currency": "POWER",
					"type": "MAIN",
					"annualPercentageRate": "60",
					"breakingMode": "INTERESTS_LOST",
					"stakeFeePercentage": "20",
					"hardCap": "100",
					"balance": "1.121212121212121",
					"subscriptionStartAtInclusive": "2022-07-13T00:00:00Z",
					"subscriptionEndAtExclusive": "2022-07-18T11:13:20Z",
					"startAtInclusive": "2022-07-14T00:00:00Z",
					"endAtExclusive": "2022-07-20T00:00:00Z",
					"ownerId": "c46f77b9"
				},
				"at": "2022-07-13T09:14:14.091113Z",
				"action": "STAKE",
				"amount": "10",
				"amountNet": "8",
				"total": "10",
				"totalNet": "8",
				"interests": "0",
				"interestsPerSec": "0.000015220700152207",
				"updatedAt": "2022-07-13T09:21:59.364082Z"
			}
		] as TermDepositItem[] */
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function stakeTermDeposit (jwt: string, code: string, id: string, amount: BigNumber, amountNet: BigNumber): Promise<void> {
	try {
		await api.post(`/termDeposits/${id}/stake?code=${code}`, {
			id: makeid(16),
			amount,
			amountNet,
		}, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function unstakeTermDeposit (jwt: string, code: string, id: string): Promise<void> {
	try {
		await api.post(`/termDeposits/${id}/unstake?code=${code}`, {
			id: makeid(16),
		}, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error) {
		displayError(error)
		throw error
	}
}

@jsonObject
export class ToonificationState {
	@jsonMember attemptNumber!: number
	@jsonMember creationDate!: string
	@jsonMember jobID!: number
	@jsonMember state!: string
}

export async function getNftById (collection: string, tokenId: string): Promise<NFTCryptopolitics> {
	try {
		const { data } = await api.get(`/${collection}/nft/${tokenId}`)

		return data as NFTCryptopolitics
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

@jsonObject
export class TradePair {
	@jsonMember checkout?: string
	@jsonMember minPrice?: BigNumber
	@jsonMember minUnitPrice?: BigNumber
	@jsonMember maxUnitPrice?: BigNumber
	@jsonMember precedence?: string
	@jsonMember price?: string
	@jsonMember productCurrency!: Currency
	@jsonMember productPrecision!: number
	@jsonMember productType!: TokenType
	@jsonMember unitPriceCurrency!: Currency
	@jsonMember unitPricePrecision!: number
	@jsonMember unitPriceType!: TokenType
	@jsonMember state!: string
	@jsonMember trend?: string
	@jsonMember trendInterval?: string
	@jsonArrayMember(String) whitelist?: string[]
}

export async function getSpotPairs (): Promise<TradePair[]> {
	const { data } = await api.get('/spot/pairs')
	return data as TradePair[]
	// Mockup for tests
	/* return [
	  {
        "productCurrency": "ALMA",
        "productType": "LONG",
        "unitPriceCurrency": "IBXE",
		"unitPricePrecision": "4",
        "unitPriceType": "MAIN",
        "state": "ENABLED",
        "precedence": "ENABLED",
        "checkout": "DISABLED",
        "productPrecision": 2,
        "fee": {
          "BUY": {
            "00000001": {
              "00000003": "5",
              "00000005": "1"
            }
          },
          "SELL": {
            "00000002": {
              "00000004": "5"
            }
          }
        }
      }
    ] as TradePair[] */
}

@jsonObject
export class DOMBuySell {
	@jsonMember(stringMapper) quantity!: BigNumber
	@jsonMember(stringMapper) price!: BigNumber
	@jsonMember(stringMapper) total!: BigNumber
	@jsonMember(stringMapper) orders!: number
}

@jsonObject
export class DOM {
	@jsonMember(stringMapper) productCurrency!: Currency
	@jsonMember(stringMapper) productType!: TokenType
	@jsonMember(stringMapper) unitPriceCurrency!: Currency
	@jsonMember(stringMapper) unitPriceType!: TokenType
	@jsonMember(stringMapper) at!: Date
	@jsonArrayMember(DOMBuySell) buys!: DOMBuySell[]
	@jsonArrayMember(DOMBuySell) sells!: DOMBuySell[]
}

export async function getDOM (productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string, pitch = 0.0001): Promise<DOM> {
	try {
		const { data } = await api.get(`/spot/depthOfMarket?productCurrency=${productCurrency.toUpperCase()}&&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&pitch=${pitch}&depth=20`)
		return data as DOM
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export type SpotOrderType = 'BUY' | 'SELL'
export type SpotOrderStatus = 'OPEN' | 'CANCELED' | 'COMPLETED'

@jsonObject
export class ValueCurrencyType {
	@jsonMember(stringMapper) value!: BigNumber
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) type!: TokenType
}

@jsonObject
export class FutureSpotOrder {
	@jsonMember(stringMapper) type!: SpotOrderType
	@jsonMember product!: ValueCurrencyType
	@jsonMember unitPrice!: ValueCurrencyType
}

export async function createTradeOrder (jwt: string, code: string, type: string,
										productValue: BigNumber, productCurrency: string, productType: string,
										unitPriceValue: BigNumber, unitPriceCurrency: string, unitPriceType: string): Promise<FutureSpotOrder> {
	try {
		const param: FutureSpotOrder = {
			type: type,
			product: {
				value: productValue,
				currency: productCurrency.toUpperCase(),
				type: productType,
			},
			unitPrice: {
				value: unitPriceValue,
				currency: unitPriceCurrency.toUpperCase(),
				type: unitPriceType,
			},
		} as FutureSpotOrder
		const { data } = await api.post(`/spot/orders?code=${code}`, param, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
		return data as FutureSpotOrder
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function getSpotFee (jwt: string, type: string,
								  productCurrency: string, productType: string,
								  unitPriceCurrency: string, unitPriceType: string,
								  amount: BigNumber): Promise<BigNumber> {
	try {
		const { data } = await api.get(`/spot/fee/${type}?productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&amount=${amount}`, { headers: { Authorization: 'Bearer ' + jwt } })
		let result = new BigNumber(0)
		for (const i in data) {
			result = result.plus(data[i])
		}
		return result
	} catch (error) {
		displayError(error)
		throw error
	}
	return new BigNumber(NaN)
}

export async function deleteSpotOrder (jwt: string, code: string, id: string): Promise<void> {
	try {
		await api.delete(`/spot/orders/${id}?code=${code}`, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function getSpotPrice (productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string): Promise<BigNumber | null> {
	try {
		const { data } = await api.get(`/spot/price?productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}`)
		const price = data.price
		if (price !== null) {
			return price as BigNumber
		}
		return null
	} catch (error) {
		displayError(error)
		throw error
	}
}

@jsonObject
export class SpotOrder extends FutureSpotOrder {
	@jsonMember(stringMapper) createdAt!: Date
	@jsonMember id!: string
	@jsonMember ownerId!: string
	@jsonMember productValueCanceled?: BigNumber
	@jsonMember productValueCreated?: BigNumber
	@jsonMember(stringMapper) status!: SpotOrderStatus
	@jsonMember trades!: number
	@jsonMember(stringMapper) updatedAt!: Date
}

@jsonObject
export class SpotOrdersResponse {
	@jsonMember offset!: number
	@jsonMember size!: number
	@jsonMember total!: number
	@jsonArrayMember(SpotOrder) results!: SpotOrder[]
}

export async function getSpotOrders (jwt: string, productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string, sort: string): Promise<SpotOrdersResponse> {
	try {
		const { data } = await api.get(`/spot/orders?status=OPEN&productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&sort=${sort}&limit=1000`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as SpotOrdersResponse
	} catch (error) {
		displayError(error)
		throw error
	}
}

export async function getPastSpotOrders (jwt: string, productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string, sort: string): Promise<SpotOrdersResponse> {
	try {
		const { data } = await api.get(`/spot/orders?status=COMPLETED,CANCELED&productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&sort=${sort}&limit=1000`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as SpotOrdersResponse
	} catch (error) {
		displayError(error)
		throw error
	}
}

@jsonObject
export class SpotTrade {
	@jsonMember(stringMapper) at!: Date
	@jsonMember id!: string
	@jsonMember buy!: SpotOrder
	@jsonMember sell!: SpotOrder
	@jsonMember price!: BigNumber
	@jsonMember spread!: BigNumber
	@jsonMember volume!: BigNumber
	@jsonMember unitPrice!: BigNumber
}

export async function getLastTrades (productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string, sort: string): Promise<SpotTrade[]> {
	try {
		const { data } = await api.get(`/spot/getLastTrades?productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&limit=20`)
		return data as SpotTrade[]
	} catch (error) {
		displayError(error)
		throw error
	}
}

export type IBDuration =
	'1s'
	| '1m'
	| '3m'
	| '5m'
	| '15m'
	| '30m'
	| '1h'
	| '2h'
	| '4h'
	| '6h'
	| '8h'
	| '12h'
	| '1d'
	| '3d'
	| '7d'
	| '30d'

export class SpotPrice {
	@jsonMember(stringMapper) startAt!: Date
	@jsonMember(stringMapper) closeAt!: Date
	@jsonMember(stringMapper) openPrice!: BigNumber
	@jsonMember(stringMapper) closePrice!: BigNumber
	@jsonMember(stringMapper) high!: BigNumber
	@jsonMember(stringMapper) low!: BigNumber
	@jsonMember(stringMapper) productCurrency!: Currency
	@jsonMember(stringMapper) productType!: TokenType
	@jsonMember(stringMapper) unitPriceCurrency!: Currency
	@jsonMember(stringMapper) unitPriceType!: TokenType
	@jsonMember(stringMapper) volumeProduct!: BigNumber
	@jsonMember(stringMapper) volumePrice!: BigNumber
	@jsonMember trades!: number
}

export async function getSpotPriceHistory (productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string, interval: IBDuration): Promise<SpotPrice[]> {
	try {
		const { data } = await api.get(`/spot/history?productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&interval=${interval}`)
		return data as SpotPrice[]
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export class SpotPriceHistory {
	@jsonMember(stringMapper) startTime!: Date
	@jsonMember(stringMapper) endTime!: Date
	@jsonMember(stringMapper) interval!: IBDuration
	@jsonArrayMember(SpotPrice) data!: SpotPrice[]
}

export async function getSpotPriceHistorySpan (productCurrency: string, productType: string, unitPriceCurrency: string, unitPriceType: string, startTime: Date, endTime: Date, interval: IBDuration): Promise<SpotPriceHistory> {
	try {
		const start = moment(startTime)
			.tz(moment.tz.guess())
			.format()
		const end = moment(endTime)
			.tz(moment.tz.guess())
			.format()
		const req = `/spot/history-span?productCurrency=${productCurrency.toUpperCase()}&productType=${productType}&unitPriceCurrency=${unitPriceCurrency.toUpperCase()}&unitPriceType=${unitPriceType}&startTime=${encodeURIComponent(start)}&endTime=${encodeURIComponent(end)}&interval=${interval}`
		const { data } = await api.get(req)
		return data as SpotPriceHistory
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export async function transferTokens (jwt: string, transferOffer: any, code: string): Promise<void> {
	try {
		await api.post(`/ledger/transfer?code=${code}`,
			{ id: makeid(16), ...transferOffer },
			{ headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function internalTransfer (jwt: string, amount: BigNumber, currency: string, typeFrom: TokenType,
										typeTo: TokenType | string, code: string): Promise<void> {
	try {
		await api.post(`/ledger/internal-transfer?code=${code}`,
			{
				id: makeid(16),
				amount,
				currency: currency.toUpperCase(),
				fromType: typeFrom,
				toType: typeTo,
			},
			{ headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function transfer (jwt: string, amount: BigNumber, currency: string, typeFrom: TokenType | string,
								typeTo: TokenType | string, code: string): Promise<void> {
	try {
		await api.post(`/ledger/transfer?code=${code}`,
			{
				id: makeid(16),
				amount,
				currency: currency.toUpperCase(),
				type: typeFrom,
				typeTo: typeTo,
			},
			{ headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}


export async function withdrawEUR (jwt: string, amount: BigNumber, iban: string, code: string): Promise<void> {
	try {
		await api.post(`/ledger/IBXE2EUR?code=${code}`,
			{
				id: makeid(16),
				amount,
				iban: iban.split(' ').join(''),
			},
			{ headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		error2fa(error, false)
	}
}

export async function wireTransferEUR (jwt: string, amount: BigNumber, iban: string, code: string): Promise<void> {
	try {
		await api.post(`/ledger/withdrawEUR?code=${code}`,
			{
				id: makeid(16),
				amount,
				iban: iban.split(' ').join(''),
			},
			{ headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
	} catch (error: any) {
		if (error.response?.status === 404) {
			alertError('cpinblocks.blockedIbanError')
		} else {
			error2fa(error, false)
		}
	}
}

export async function getAvailableApps (): Promise<App3rdParty[]> {
	// TODO: API call to GET all available apps to connect when backend ready

	let response = null

	if (env('VUE_APP_ENV') === 'staging') {
		response = await require('@/utils/app-conf-staging')
	} else if (env('VUE_APP_ENV') === 'testnet') {
		response = await require('@/utils/app-conf-testnet')
	} else if (env('VUE_APP_ENV') === 'prod') {
		response = await require('@/utils/app-conf-production')
	} else {
		return []
	}

	return response.data.apps as App3rdParty[]
}

export async function getStaticLaunchpadConf (): Promise<Record<string, any>[]> {
	// TODO: API call to GET all available apps to connect when backend ready
	const response = await require('@/conf/launchpad')
	return response.data.projects as Record<string, any>[]
}

export async function getProjectLaunchpadConf (projectName: string): Promise<Record<string, any> | null> {
	// TODO: API call to GET specific app to connect when backend ready
	const projects = await getStaticLaunchpadConf()
	const project = projects.find(project => project.name === projectName)
	if (project) {
		return project
	} else {
		return null
	}
}

export function add3rdPartyApp (app: Partial<App3rdParty>, redirectUri: string, clientId?: string, sessionState?: string): void {
	const url = `${connectApiBaseUrl}/openid-connect/user-initiated-link/${app.idpAlias}?clientId=${clientId}&sessionState=${sessionState}&redirectUri=${redirectUri}`

	window.location.replace(url)
}

export async function delete3rdPartyApp (jwt: string, app: Partial<App3rdParty>): Promise<void> {
	try {
		await connectApi.delete(`/openid-connect/link/${app.idpAlias}`, { headers: { Authorization: 'Bearer ' + jwt } })
	} catch (error: any) {
		displayError(error)
	}
}

// export async function getNftCollection (jwt: string, collection: string, ownerId: string, size = 10): Promise<NFTResponse> {
// 	try {
// 		const { data } = await searchApi.get(`/nft/${collection}?ownerId=${ownerId}&size=${size}`, { headers: { Authorization: 'Bearer ' + jwt } })

// 		return data as NFTResponse
// 	} catch (error: any) {
// 		displayError(error)
// 		throw error
// 	}
// }

export async function isUserBuyback (jwt: string): Promise<boolean> {
	try {
		const { data } = await api.get('/buyback', { headers: { Authorization: 'Bearer ' + jwt } })
		if (data.length > 0) {
			return true
		} else {
			return false
		}
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export interface INFTTransferBody {
	nft: {
		collection: string,
		tokenId: string,
		supply: number,
		maxSupply: number
	},
	network: string,
	to: string,
	feeId?: string,
	fee?: Fee,
	id?: string
}

export async function NFTTransferOutOrGetNFTFee (jwt: string, code: string, payload: INFTTransferBody, showFeeEstimation = true): Promise<TransferOutFee | null> {
	try {
		let url = ''

		if (showFeeEstimation) {
			url = `/nft/${payload.nft.collection}/${payload.nft.tokenId}/migrate?estimate=${showFeeEstimation}`
		} else {
			url = `/nft/${payload.nft.collection.toUpperCase()}/${payload.nft.tokenId}/migrate?estimate=${showFeeEstimation}&code=${code}&feeId=${payload.feeId}`
		}

		const { data } = await api.post(url, payload, { headers: { Authorization: 'Bearer ' + jwt } })
		await updateStored2FA(code)
		const result = data as TransferOutFee
		const localTime = new Date().getTime()
		const serverTime = new Date(result.createdAt).getTime()
		result.expiresAt = new Date(localTime - serverTime + new Date(result.expiresAt).getTime()).toISOString()
		return result
	} catch (error: any) {
		// FIXME: when closing AddWithdrawal component after sending transferOut request
		// FIXME: the Watch properties in FeeSelector send new request "NFTTransferOutOrGetNFTFee()" - that is unnecessary on this step
		// FIXME: that causes an error - need to find the way to fix it
		console.log('error: ', error)
	}
	return null
}

export class PaymentSession {
	@jsonMember id!: string
	@jsonMember preCreatedAt!: Date
	@jsonMember provider!: string
	@jsonMember paymentIntentId?: string
	@jsonMember feeFixed!: BigNumber
	@jsonMember feeVariablePercentage!: BigNumber
	@jsonMember fee!: BigNumber
	@jsonMember product!: ValueCurrencyType
	@jsonMember unitPrice!: ValueCurrencyType
	@jsonMember priceNet!: BigNumber
	@jsonMember price!: BigNumber
	@jsonMember lang?: string
	@jsonMember owner!: string
	@jsonMember status!: string
}

export async function getCheckoutSessionId (jwt: string, provider: string, paymentIntentId: string | undefined, amountFrom: BigNumber, currencyFrom: Currency | undefined, currencyTo: Currency, typeTo: TokenType, lang: string): Promise<PaymentSession> {
	let localLang = lang
	if (!lang) {
		localLang = 'en'
	}
	try {
		let url = `/checkout/${provider}/session?amountFrom=${amountFrom}&currencyTo=${currencyTo.toUpperCase()}&typeTo=${typeTo}&lang=${localLang}`
		if (paymentIntentId) {
			url += `&paymentIntentId=${paymentIntentId}`
		}
		if (currencyFrom && currencyFrom !== 'EUR') {
			url += `&currencyFrom=${currencyFrom.toUpperCase()}`
		}
		const { data } = await api.get(url, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as PaymentSession
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export async function getPaymentLink (jwt: string, provider: string, sessionId: string, callbackUrl: string, cancelUrl: string): Promise<string> {
	try {
		const callbackUrlEncoded = encodeURI(callbackUrl)
		const cancelUrlEncoded = encodeURI(cancelUrl)
		const { data } = await api.post(`/checkout/${provider}/session/${sessionId}?callbackUrl=${callbackUrlEncoded}&cancelUrl=${cancelUrlEncoded}`, undefined,
			{ headers: { Authorization: 'Bearer ' + jwt } })
		return data.url as string
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export async function payUsingCrypto (jwt: string, provider: string, sessionId: string, code: string): Promise<string> {
	try {
		const { data } = await api.post(`/checkout/${provider}/session/${sessionId}?code=${code}`, undefined,
			{ headers: { Authorization: 'Bearer ' + jwt } })
		return data.url as string
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export async function swapUsingCrypto (jwt: string, code: string): Promise<string> {
	try {
		const { data } = await api.post(`/swap?code=${code}`, undefined,
			{ headers: { Authorization: 'Bearer ' + jwt } })
		return data.url as string
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export async function submitCheckout (
	jwt: string,
	paymentReference: string,
	amount: number,
	context: string,
	lang: string
): Promise<void> {
	try {
		return await api.post(
			'/checkout/manual/email',
			null,
			{
				params: {
					paymentReference,
					context,
					amount,
					lang,
				},
				headers: {
					Authorization: `Bearer ${jwt}`,
					'Content-Type': 'application/json',
				},
			})
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

export class OnboardingPaymentIntent {
	@jsonMember id!: number
	@jsonMember tokenEmissionEventId!: number
	@jsonMember email!: string
	@jsonMember eventTime!: Date
	@jsonMember ibexUserId?: string
	@jsonMember(stringMapper) amount!: BigNumber
	@jsonMember(stringMapper) currency!: Currency
	@jsonMember(stringMapper) token!: Currency
	@jsonMember(stringMapper) tokenType!: TokenType
	@jsonMember tokenIssuerSiteUrl?: string | null
	@jsonMember tokenEmissionEventName?: string | null
	@jsonMember lang!: string
}

export async function getPaymentIntent (jwt: string, paymentIntentId: string): Promise<OnboardingPaymentIntent> {
	try {
		const { data } = await onboardingApi.get(`/token-emission-event/payment-intent/${paymentIntentId}`,
			{ headers: { Authorization: 'Bearer ' + jwt } })
		return data as OnboardingPaymentIntent
	} catch (error: any) {
		displayError(error)
		throw error
	}
}

// TODO: add a check on the currency existence in the env to avoid useless API calls
export async function getTotalHolders (currency: Currency): Promise<number> {
	try {
		const { data } = await api.get(`/ledger/stats/currency/${currency.toUpperCase()}`)
		return data.totalHolders
	} catch (error: any) {
		displayError(error)
		return 0
	}
}

// TODO: add a check on the currency existence in the env to avoid useless API calls
export async function getCheckoutStats (currency: Currency, type: TokenType): Promise<number[]> {
	try {
		const { data } = await api.get(`/checkout/stats?token=${currency.toUpperCase()}&type=${type}`)
		return [data.participants, data.collectedAmount]
	} catch (error: any) {
		displayError(error)
		return [0, 0]
	}
}

export async function getAccount (jwt: string, accountCurrency: Currency, accountType: TokenType = 'MAIN'): Promise<Account[]> {
	try {
		const { data } = await api.get(`/ledger/accounts?currencies=${accountCurrency.toUpperCase()}&types=${accountType}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data.map((raw: any) => TypedJSON.parse(raw, Account))
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

export class CryptoDetail {
	@jsonMember currency!: string
	@jsonMember type!: string
	@jsonMember balance!: number
	@jsonMember numberOfAccount!: number
	@jsonMember lastBalance!: number
	@jsonMember lastBalanceIn!: number
	@jsonMember lastBalanceOut!: number
	@jsonMember lastBalanceDelta!: number
	@jsonMember lastBalanceDeltaPercentage!: number
}

export class Reporting {
	@jsonMember userCount!: number
	@jsonMember userCpCount!: number
	@jsonMember newUsers!: number
	@jsonMember kycUsers!: number
	@jsonMember numberOfElrondWallet!: number
	@jsonMember kycUsersForm!: number
	@jsonArrayMember(String) currencies!: string[]
	@jsonArrayMember(CryptoDetail) cryptoDetailed!: CryptoDetail[]
}

export async function getReporting (jwt: string, start: Date, end: Date): Promise<Reporting> {
	try {
		const { data } = await api.get(`/reporting/statistics?startTime=${start.toISOString()}&endTime=${end.toISOString()}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as Reporting
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

export async function getSpotPrecedence (jwt: string, id: string): Promise<string> {
	try {
		const { data } = await api.get(`/spot/precedence/${id}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data as string
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

export async function getSpotPrecedenceCertificate (jwt: string, id: string): Promise<string> {
	try {
		const { data } = await api.get(`/spot/precedence/${id}/certificate`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

@jsonObject
export class DepositRange {
	@jsonMember(stringMapper) capital!: BigNumber
	@jsonMember(stringMapper) earnings!: BigNumber
	@jsonMember(stringMapper) startAt!: Date
	@jsonMember(stringMapper) endAt?: Date

	computeEarnings (): BigNumber {
		return this.earnings
		/*
		if (this.endAt) {
			return this.earnings
		}
		let duration = moment.duration(moment().diff(moment(this.startAt)))
		const t = new BigNumber(duration.asMilliseconds()).div(new BigNumber(moment.duration('3', 'years').asMilliseconds()))
		return new BigNumber(
			new BigNumber(
				Math.pow(t.toNumber(), 3.4))
				.multipliedBy(this.capital)
				.toFixed(18)
		) */
	}
}

@jsonObject
export class Deposit {
	@jsonMember(stringMapper) initialCapital!: BigNumber
	@jsonMember(stringMapper) currentCapital!: BigNumber
	@jsonMember(stringMapper) earnings!: BigNumber
	@jsonMember(stringMapper) currentBalance!: BigNumber
	@jsonMember(stringMapper) startAt!: Date
	@jsonMember(stringMapper) endAt!: Date
	@jsonArrayMember(DepositRange) ranges!: DepositRange[]

	computeEarnings (): BigNumber {
		let result: BigNumber = new BigNumber(0)
		for (const dr of this.ranges) {
			result = result.plus(dr.computeEarnings())
		}
		return result
	}
}

@jsonObject
export class LongDeposit {
	@jsonMember accountId!: string
	@jsonMember currency!: string
	@jsonMember type!: string
	@jsonMember(stringMapper) totalCapital!: BigNumber
	@jsonMember(stringMapper) totalEarnings!: BigNumber
	@jsonMember(stringMapper) totalBalance!: BigNumber
	@jsonArrayMember(Deposit) deposits!: Deposit[]

	getCurrentCapital (): BigNumber {
		let result = new BigNumber(0)
		for (const o of this.deposits) {
			result = result.plus(o.currentCapital)
		}
		return result
	}

	getInitialCapital (): BigNumber {
		let result = new BigNumber(0)
		for (const o of this.deposits) {
			result = result.plus(o.initialCapital)
		}
		return result
	}

	getEarnings (): BigNumber {
		let result = new BigNumber(0)
		for (const o of this.deposits) {
			result = result.plus(o.earnings)
		}
		return result
	}

	getBalance (): BigNumber {
		let result = new BigNumber(0)
		for (const o of this.deposits) {
			result = result.plus(o.currentBalance)
		}
		return result
	}

	computeEarnings (): BigNumber {
		let result = new BigNumber(0)
		for (const o of this.deposits) {
			result = result.plus(o.computeEarnings())
		}
		return result
	}
}

export async function getLongDepositInfo (jwt: string, currency: string): Promise<LongDeposit> {
	try {
		const serializer = new TypedJSON(LongDeposit)
		const { data } = await api.get(`/ledger/earnings/${currency.toUpperCase()}/LONG`, { headers: { Authorization: 'Bearer ' + jwt } })
		return serializer.parse(data) as LongDeposit
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

export async function changeEmailSharingConsent (jwt: string, currency: Currency, consent: boolean): Promise<void> {
	try {
		await api.post(`/ledger/owner/consent?currency=${currency.toUpperCase()}&consent=${consent}`, {}, { headers: { Authorization: 'Bearer ' + jwt } })
	} catch (error: any) {
		displayError(error)
		throw error.response
	}
}

export async function getEmailSharingConsent (jwt: string, currency: Currency): Promise<boolean> {
	try {
		const { data } = await api.get(`/ledger/owner/consent/${currency.toUpperCase()}`, { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		displayError(error)
		throw error.response
		return false
	}
}

export async function getAutoCompleteResults (jwt: string, input: string, types: string[] = ['address']): Promise<google.maps.places.AutocompletePrediction[]> {
	try {
		const { data } = await api.get('/google/place/autocomplete', {
			params: {
				input: input,
				types: types.join('|'),
			},
			headers: { Authorization: 'Bearer ' + jwt },
		})
		return data.predictions as google.maps.places.AutocompletePrediction[]
	} catch (error: any) {
		throw error.response
	}
}

export async function getPlaceDetails (jwt: string, placeId: string): Promise<google.maps.places.PlaceResult> {
	try {
		const { data } = await api.get(`/google/placedetails/${placeId}`, {
			headers: { Authorization: 'Bearer ' + jwt },
		})
		return data as google.maps.places.PlaceResult
	} catch (error: any) {
		throw error.response
	}
}

export async function updateIban (iban: Iban, jwt: string): Promise<boolean> {
	try {
		await api.put(`/iban/${iban.value}`, iban, { headers: { Authorization: 'Bearer ' + jwt } })
		return true
	} catch (error: any) {
		displayError(error)
		return false
	}
}

export class KYBCompanyResponse extends CompanyIdentity {
	@jsonMember city!: string
	@jsonMember postCode!: string
	@jsonMember street!: string
	@jsonMember alternativeName!: string
}

export class KYBLink {
	@jsonMember rel!: string
	@jsonMember href!: string
}

export class KYBRecordInfo {
	@jsonMember id!: string
	@jsonMember timestamp!: string
	@jsonMember size!: number
	@jsonMember(KYBCompanyResponse) fields!: KYBCompanyResponse
}

export class KYBRecord {
	@jsonArrayMember(KYBLink) links?: KYBLink[]
	@jsonMember record?: KYBRecordInfo
}

export class KYBResponse {
	@jsonMember({ name: 'total_count' }) totalCount!: number
	@jsonArrayMember(KYBLink) links?: KYBLink[]
	@jsonArrayMember(KYBRecord) records?: KYBRecord[]
}

export async function getCompanyInformation (siret: string): Promise<KYBCompanyResponse | undefined> {
	const params = new URLSearchParams({
		select: 'siret, datecreationetablissement as creationDate, codepostaletablissement as postCode, libellecommuneetablissement as city, adresseetablissement as street, denominationunitelegale as name, nomunitelegale as alternativeName',
		where: `siret = ${siret}`,
		limit: '1',
	})

	try {
		const data = (await sireneApi.get('records', { params })).data as KYBResponse
		if (data.records && data.records.length > 0) return data.records[0].record?.fields
	} catch (error: any) {
		throw error.response
	}
}

export function isAxiosError (candidate: unknown): candidate is AxiosError {
	if (candidate && typeof candidate === 'object' && 'isAxiosError' in candidate) {
		return true
	}
	return false
}

export class FormParameters {
	@jsonMember key!: string
	@jsonMember({ name: 'X-Amz-Date' }) amzDate!: string
	@jsonMember({ name: 'X-Amz-Credential' }) amzCredential!: string
	@jsonMember action!: string
	@jsonMember({ name: 'X-Amz-Algorithm' }) amzAlgorithm!: string
	@jsonMember({ name: 'x-amz-server-side-encryption' }) amzEncryption!: string
	@jsonMember({ name: 'Content-Type' }) contentType!: string
	@jsonMember({ name: 'X-Amz-Signature' }) amzSignature!: string
	@jsonMember policy!: string
	@jsonMember file?: File
}

export async function getFormParameters (jwt: string, fileName: string, verificationFactor: VerificationFactor, repeatableId?: string): Promise<FormParameters | undefined> {
	try {
		return (await api.get(`/verification/factor/${verificationFactor}/${repeatableId !== undefined ? (repeatableId + '/') : ''}form/parameters`, {
			params: { fileName: fileName },
			headers: { Authorization: 'Bearer ' + jwt },
		})).data as FormParameters
	} catch (error: any) {
	}
}

export async function uploadVerificationFactorFile (formParameters: FormParameters, file: File): Promise<boolean | null> {
	const {
		action,
		...params
	} = formParameters

	params.file = file
	const formData = new FormData()
	for (const [key, value] of Object.entries(params)) {
		formData.append(key, value)
	}

	try {
		const response = await axios.post(action, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			validateStatus: function (status) {
				return status >= 200 && status < 300
			},
		})

		return true
	} catch (error) {
		displayError(error)
		return null
	}
}

export async function mockJumioCallback (ownerId: string): Promise<void> {
	try {
		await api.post('/verification/id-document',
			qs.stringify({
				callbackDate: new Date().toISOString(),
				customerId: ownerId,
				idScanStatus: 'SUCCESS',
				verificationStatus: 'APPROVED_VERIFIED',
				idFirstName: 'mock-jumio',
				idLastName: 'mock-jumio',
			}), {
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
			})
	} catch (error: any) {
	}
}

export async function getOwnerGoat (jwt: string): Promise<any[]> {
	try {
		const { data } = await api.get('/ledger/owner/goat', { headers: { Authorization: 'Bearer ' + jwt } })
		return data
	} catch (error: any) {
		displayError(error)
	}
	return []
}

export async function setOwnerGoat (jwt: string, goat: any): Promise<void> {
	try {
		await api.post('/ledger/owner/goat', goat, { headers: { Authorization: 'Bearer ' + jwt } })
	} catch (error: any) {
		displayError(error)
	}
}

export async function downloadAccountStatement (jwt: string, year: number, month: number | null): Promise<void> {
	try {
		if (month == null) {
			const response = await api.get(`/ledger/account-statement/${year}`, {
				timeout: 45000,
				headers: {
					Accept: 'application/pdf',
					Authorization: 'Bearer ' + jwt
				},
				responseType: 'blob',
			})
			const filename: string = regExpFilename.exec(response.headers['content-disposition'])?.groups?.filename ?? `ibex_${year}.pdf`
			await download(response.data, filename)
		} else {
			const response = await api.get(`/ledger/account-statement/${year}/${month}`, {
				timeout: 45000,
				headers: {
					Accept: 'application/pdf',
					Authorization: 'Bearer ' + jwt
				},
				responseType: 'blob',
			})
			const filename: string = regExpFilename.exec(response.headers['content-disposition'])?.groups?.filename ?? `ibex_${year}_${month}.pdf`
			await download(response.data, filename)
		}
	} catch (error: any) {
		displayError(error)
	}
}

export async function setKYC1 (jwt: string): Promise<void> {
	try {
		await api.post('/verification/state?verificationPath=KYC&status=1', {}, { headers: { Authorization: 'Bearer ' + jwt } })
	} catch (error: any) {
		displayError(error)
	}
}

export async function getSpotSummary (jwt: string): Promise<TradePair[]> {
	try {
		const { data } = await api.get('/spot/summary')
		data.pairs.sort((a: TradePair, b: TradePair) => {
			const s1 = a.productCurrency + '_' + a.productType + ' ' + a.unitPriceCurrency + ' ' + a.unitPriceType;
			const s2 = b.productCurrency + '_' + b.productType + ' ' + b.unitPriceCurrency + ' ' + b.unitPriceType;
			return s1.localeCompare(s2)
		})
		return data.pairs
	} catch (error: any) {
		displayError(error)
	}
	return []
}
